import { styled } from '@linaria/react'
import React, { ComponentProps, FC } from 'react'
import { buttonResetCss } from '../styles/buttonResetCss'

/**
 * Важно не расширять компонент с помощью className,
 * т.к. у linaria есть проблема с упорядочиванием class.
 */
export const UnstyledButton: FC<ComponentProps<typeof Button>> = (props) => {
  return <Button type="button" {...props} />
}

const Button = styled.button`
  ${buttonResetCss};
`
