import { useDispatch } from 'react-redux'
import React, { FC, useEffect } from 'react'
import { LegalDocument, completeTextAction } from 'actions/completeTextAction'
import ModalNavigation from '../../../presentational/ModalNavigation'
import { Content } from '../../../presentational/modal'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { RenderText } from 'components/page/Boarding/RenderText/RenderText'
import { resoleConfidentiality } from 'components/page/Legal/Confidentiality/resolveConfidentiality'
import { PlainWrapper } from 'components/page/Legal/PlainWrapper'
import { WrapperLayout } from 'components/layout/WrapperLayout'
import { InnerContent } from 'components/presentational/InnerContent'
import { FormattedMessage } from 'react-intl'
import { ConfidentialityTextNext } from './ConfidentialityTextNext'
import { ModalLayoutIndex } from 'components/layout/ModalLayout/ModalLayoutIndex'

const ConfidentialityIndex: FC<{
  fade?: boolean
  plain?: boolean
  redesign?: boolean
}> = ({ fade, plain, redesign }) => {
  const dispatch = useDispatch()

  const { text, textLoading, gdprBlocked, partnerId } = useShallowEqualSelector(
    ({
      textReducer: { text, textLoading },
      errorReducer: { gdprBlocked },
      systemReducer: { partnerId },
    }) => ({
      text,
      textLoading,
      gdprBlocked,
      partnerId,
    })
  )

  const confidentiality = resoleConfidentiality(partnerId)

  useEffect(() => {
    dispatch(completeTextAction(LegalDocument.confidentiality))
  }, [dispatch, confidentiality])

  if (redesign) {
    return <ConfidentialityTextNext text={text} textLoading={textLoading} />
  }

  if (plain) {
    return (
      <WrapperLayout>
        <PlainWrapper text={text!} plain={true} loading={textLoading} />
      </WrapperLayout>
    )
  }

  return (
    <WrapperLayout withBaseUrl={false}>
      <ModalLayoutIndex
        data-name={'modal-confidentiality'}
        fade={fade}
        closeDisabled={gdprBlocked}
      >
        <Content>
          <ModalNavigation
            historyBack={true}
            title={
              <FormattedMessage
                id="boarding.confidentiality_link"
                defaultMessage="Политики в отношении обработки персональных данных"
              />
            }
          />
          <InnerContent>
            <RenderText text={text} plain={true} loading={textLoading} />
          </InnerContent>
        </Content>
      </ModalLayoutIndex>
    </WrapperLayout>
  )
}

export default ConfidentialityIndex
