import React, { FC, SVGProps } from 'react'

export const QuestionSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22.909C18.0249 22.909 22.9091 18.0248 22.9091 11.9999C22.9091 5.97499 18.0249 1.09082 12 1.09082C5.97508 1.09082 1.09091 5.97499 1.09091 11.9999C1.09091 18.0248 5.97508 22.909 12 22.909ZM9.80176 9.04516C9.86453 8.68953 10.337 7.63628 11.9897 7.63628C12.8083 7.63628 13.3028 7.94394 13.6209 8.29198C13.9647 8.66825 14.1291 9.12693 14.1718 9.39371C14.2082 9.68918 14.1599 10.2337 13.8435 10.7483C13.5469 11.2307 12.9636 11.7718 11.7657 12.0232C11.2003 12.1418 10.8298 12.6783 10.9093 13.242V14.1817C10.9093 14.7842 11.3977 15.2726 12.0002 15.2726C12.6027 15.2726 13.0911 14.7842 13.0911 14.1817V13.9149C14.3372 13.4757 15.1816 12.7377 15.7021 11.8911C16.3132 10.8971 16.4305 9.83105 16.3334 9.09738L16.3321 9.08736L16.3306 9.07736C16.2348 8.44415 15.9095 7.5621 15.2315 6.82014C14.5233 6.04519 13.4547 5.45446 11.9897 5.45446C9.07523 5.45446 7.86224 7.48141 7.65316 8.66589C7.54843 9.25922 7.94451 9.8251 8.53783 9.92983C9.13115 10.0346 9.69703 9.63848 9.80176 9.04516ZM12 18.5454C12.6025 18.5454 13.0909 18.0569 13.0909 17.4545C13.0909 16.852 12.6025 16.3635 12 16.3635C11.3975 16.3635 10.9091 16.852 10.9091 17.4545C10.9091 18.0569 11.3975 18.5454 12 18.5454Z"
        fill="#2E2A29"
      />
    </svg>
  )
}
