import styled from 'styled-components'
import { media, modalPaddingCss } from 'components/presentational/index'

export const InnerContent = styled.div`
  margin-bottom: 35px;
  ${media.phone`
    max-height: none;
  `};
  ${modalPaddingCss}
`
