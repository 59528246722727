import {
  BottomSheet,
  useBottomSheet,
} from 'components/designSystem/BottomSheet/BottomSheet'
import { useAppDispatch } from 'hooks/useAppDispatch'
import React, { ComponentPropsWithoutRef, FC, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { goBackAction } from 'actions/route/routeAction'

/** Показывается на пути @path */
export const BottomSheetOnRoute: FC<
  { path: string } & ComponentPropsWithoutRef<typeof BottomSheet>
> = ({ path, children, onClose, ...bottomSheetProps }) => {
  const dispatch = useAppDispatch()
  const { ref, open, close, isOpen } = useBottomSheet()

  const match = useRouteMatch({
    path,
    exact: true,
  })

  const shouldOpenBottomSheet = Boolean(match)

  useEffect(() => {
    if (shouldOpenBottomSheet) {
      open()
    } else if (isOpen) {
      close()
    }
  }, [close, isOpen, open, shouldOpenBottomSheet])

  const handleClose = () => {
    if (match) {
      // Уходим назад, если путь не поменялся.
      onClose ? onClose() : dispatch(goBackAction())
    }
  }

  return (
    <BottomSheet ref={ref} onClose={handleClose} {...bottomSheetProps}>
      {children}
    </BottomSheet>
  )
}
