import { BottomSheetOnRoute } from 'components/designSystem/BottomSheet/BottomSheetOnRoute'
import { appConfidentialityPath, confidentialityPath } from 'components/paths'
import { useBaseUrl } from 'components/system/baseUrl/BaseUrlContext'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import React, { FC } from 'react'
import ConfidentialityIndex from '../Legal/Confidentiality/ConfidentialityIndex'

export const ConfidentialityBottomSheet: FC = () => {
  const baseUrl = useBaseUrl()

  return (
    <>
      <BottomSheetOnRoute path={mergeAllUrls(baseUrl, appConfidentialityPath)}>
        <ConfidentialityIndex redesign />
      </BottomSheetOnRoute>
      <BottomSheetOnRoute path={mergeAllUrls(baseUrl, confidentialityPath)}>
        <ConfidentialityIndex redesign />
      </BottomSheetOnRoute>
    </>
  )
}
