import { modalOverlayClickAction } from 'actions/system/modalAction'
import { ModalLayoutIndex } from 'components/layout/ModalLayout/ModalLayoutIndex'

export const bodyHiddenClass = 'body_hidden'

/**
 * @deprecated
 */
export default ModalLayoutIndex

/**
 * TODO удалить после перехода на uniweb, legacy.
 * Удаление на следующий раз.
 */
export function defaultModalOverlayClickHandler() {
  this.props.dispatch(modalOverlayClickAction())
}
