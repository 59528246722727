import { mambaLite } from 'common/constants'

const confidentialityMain: Record<string, string> = {
  ru: require('./text/ru.html'),
  bg: require('./text/bg.html'),
  de: require('./text/de.html'),
  el: require('./text/el.html'),
  en: require('./text/en.html'),
  fr: require('./text/fr.html'),
  id: require('./text/id.html'),
  ko: require('./text/ko.html'),
  ms: require('./text/ms.html'),
  ro: require('./text/ro.html'),
  sr: require('./text/sr.html'),
  sv: require('./text/sv.html'),
  th: require('./text/th.html'),
  tr: require('./text/tr.html'),
  vi: require('./text/vi.html'),
  zh: require('./text/zh.html'),
  ['zh-tw']: require('./text/zh-tw.html'),
}

const confidentialityLite: Record<string, string> = {
  ru: require('./textLite/ru.html'),
  en: require('./textLite/en.html'),
}

export const resoleConfidentiality = (
  partnerId: number
): Record<string, string> => {
  if (partnerId === mambaLite) {
    return confidentialityLite
  }

  return confidentialityMain
}
