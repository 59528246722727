import React, { FC } from 'react'

/**
 * @deprecated src/common/components/presentational/svg/BackSvg.tsx
 * @param fillColor
 * @returns {JSX.Element}
 * @constructor
 */
export const Back2Svg: FC<{ fillColor?: string }> = ({
  fillColor = '#0079C0',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M7.275 10.875l6.3-6.3L12 3l-9 9 9 9 1.575-1.575-6.3-6.3H21v-2.25z"
    />
  </svg>
)
